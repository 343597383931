
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexVwrtKZq1_XFQowHiMNVrq_QxiDvIA0X5SDSbhoi5bXYMeta } from "/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/pages/index.vue?macro=true";
import { default as indexY_w28HPiomaHeTo61ZPjSuyN_KNrn8RufMT_45GLW2KsEMeta } from "/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/pages/[[lang]]/index.vue?macro=true";
import { default as startB14UVguzn4VPOAXaHoU8RgzTjoaYsRwoF44hyZdH2vUMeta } from "/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/pages/[[lang]]/voucher/start.vue?macro=true";
import { default as startdK8fPs0382azAb_45fLmSnYxIU2ZinAUkLrNhmn1l7sHAMeta } from "/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/pages/[[lang]]/purchase/start.vue?macro=true";
import { default as checkouthNlbVAlIfcAfiV6sHVbWlHhPeljT1RiPDyoe8wyeI14Meta } from "/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/pages/[[lang]]/service-booking/checkout.vue?macro=true";
import { default as korjdB9ZW1Vap2OlvYPZUPmWr8IJ_vYYFABKZEhJjez58Meta } from "/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue?macro=true";
import { default as confirmationoo_45EPBcmdoLxepiTC3qhGBpbNBCFmbxqMnXSI8LQ0IcMeta } from "/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue?macro=true";
import { default as confirmation2Y2Fe00JI8fBxwKbq_r4mZVYF8N1GmecO2_45veodkwdMMeta } from "/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue?macro=true";
import { default as buyerVIMQkhIO5n78m1o41IstbdhoWGNZsziBg2GI12P7tOgMeta } from "/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as buyeryHVnp_45RmmWc2Q8N4jLeeuTTh66VfloRGRBzsDGZL2TUMeta } from "/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as payment2h8uZtwumJ3B8VKdI7pSo2n2rPbKhpGW0gAYqCeHQ7cMeta } from "/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue?macro=true";
import { default as challenge9eMHRp6jr_45nIPRwOGGIbZXcVPVY6Ufg_uNtZ60Ud1zUMeta } from "/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue?macro=true";
import { default as paymentfmfE_45cM_gBvhKAvDWijeMkBgN7CUeBoAAnR2G8WRengMeta } from "/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue?macro=true";
import { default as SpaList_46pagea08lvZStwIsfjzLzKQS_45CFHcTSjlp3tQRaAvWf7onSYMeta } from "/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/spa/SpaList.page.vue?macro=true";
import { default as SpaDetail_46page9AlauHM_45F_OabFbldmO6NFNg3T6D6z1ttL7coycWM_45QMeta } from "/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/spa/SpaDetail.page.vue?macro=true";
import { default as ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta } from "/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue?macro=true";
export default [
  {
    name: indexVwrtKZq1_XFQowHiMNVrq_QxiDvIA0X5SDSbhoi5bXYMeta?.name ?? "index",
    path: indexVwrtKZq1_XFQowHiMNVrq_QxiDvIA0X5SDSbhoi5bXYMeta?.path ?? "/",
    props: indexVwrtKZq1_XFQowHiMNVrq_QxiDvIA0X5SDSbhoi5bXYMeta?.props ?? false,
    meta: indexVwrtKZq1_XFQowHiMNVrq_QxiDvIA0X5SDSbhoi5bXYMeta || {},
    alias: indexVwrtKZq1_XFQowHiMNVrq_QxiDvIA0X5SDSbhoi5bXYMeta?.alias || [],
    redirect: indexVwrtKZq1_XFQowHiMNVrq_QxiDvIA0X5SDSbhoi5bXYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/pages/index.vue")
  },
  {
    name: indexY_w28HPiomaHeTo61ZPjSuyN_KNrn8RufMT_45GLW2KsEMeta?.name ?? "lang",
    path: indexY_w28HPiomaHeTo61ZPjSuyN_KNrn8RufMT_45GLW2KsEMeta?.path ?? "/:lang?",
    props: indexY_w28HPiomaHeTo61ZPjSuyN_KNrn8RufMT_45GLW2KsEMeta?.props ?? false,
    meta: indexY_w28HPiomaHeTo61ZPjSuyN_KNrn8RufMT_45GLW2KsEMeta || {},
    alias: indexY_w28HPiomaHeTo61ZPjSuyN_KNrn8RufMT_45GLW2KsEMeta?.alias || [],
    redirect: indexY_w28HPiomaHeTo61ZPjSuyN_KNrn8RufMT_45GLW2KsEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/pages/[[lang]]/index.vue")
  },
  {
    name: startB14UVguzn4VPOAXaHoU8RgzTjoaYsRwoF44hyZdH2vUMeta?.name ?? "lang-voucher-start",
    path: startB14UVguzn4VPOAXaHoU8RgzTjoaYsRwoF44hyZdH2vUMeta?.path ?? "/:lang?/voucher/start",
    props: startB14UVguzn4VPOAXaHoU8RgzTjoaYsRwoF44hyZdH2vUMeta?.props ?? false,
    meta: startB14UVguzn4VPOAXaHoU8RgzTjoaYsRwoF44hyZdH2vUMeta || {},
    alias: startB14UVguzn4VPOAXaHoU8RgzTjoaYsRwoF44hyZdH2vUMeta?.alias || [],
    redirect: startB14UVguzn4VPOAXaHoU8RgzTjoaYsRwoF44hyZdH2vUMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/pages/[[lang]]/voucher/start.vue")
  },
  {
    name: startdK8fPs0382azAb_45fLmSnYxIU2ZinAUkLrNhmn1l7sHAMeta?.name ?? "lang-purchase-start",
    path: startdK8fPs0382azAb_45fLmSnYxIU2ZinAUkLrNhmn1l7sHAMeta?.path ?? "/:lang?/purchase/start",
    props: startdK8fPs0382azAb_45fLmSnYxIU2ZinAUkLrNhmn1l7sHAMeta?.props ?? false,
    meta: startdK8fPs0382azAb_45fLmSnYxIU2ZinAUkLrNhmn1l7sHAMeta || {},
    alias: startdK8fPs0382azAb_45fLmSnYxIU2ZinAUkLrNhmn1l7sHAMeta?.alias || [],
    redirect: startdK8fPs0382azAb_45fLmSnYxIU2ZinAUkLrNhmn1l7sHAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/pages/[[lang]]/purchase/start.vue")
  },
  {
    name: checkouthNlbVAlIfcAfiV6sHVbWlHhPeljT1RiPDyoe8wyeI14Meta?.name ?? "lang-service-booking-checkout",
    path: checkouthNlbVAlIfcAfiV6sHVbWlHhPeljT1RiPDyoe8wyeI14Meta?.path ?? "/:lang?/service-booking/checkout",
    props: checkouthNlbVAlIfcAfiV6sHVbWlHhPeljT1RiPDyoe8wyeI14Meta?.props ?? false,
    meta: checkouthNlbVAlIfcAfiV6sHVbWlHhPeljT1RiPDyoe8wyeI14Meta || {},
    alias: checkouthNlbVAlIfcAfiV6sHVbWlHhPeljT1RiPDyoe8wyeI14Meta?.alias || [],
    redirect: checkouthNlbVAlIfcAfiV6sHVbWlHhPeljT1RiPDyoe8wyeI14Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/pages/[[lang]]/service-booking/checkout.vue")
  },
  {
    name: korjdB9ZW1Vap2OlvYPZUPmWr8IJ_vYYFABKZEhJjez58Meta?.name ?? "lang-purchase-packagepurchaseuuid-ko",
    path: korjdB9ZW1Vap2OlvYPZUPmWr8IJ_vYYFABKZEhJjez58Meta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/ko",
    props: korjdB9ZW1Vap2OlvYPZUPmWr8IJ_vYYFABKZEhJjez58Meta?.props ?? false,
    meta: korjdB9ZW1Vap2OlvYPZUPmWr8IJ_vYYFABKZEhJjez58Meta || {},
    alias: korjdB9ZW1Vap2OlvYPZUPmWr8IJ_vYYFABKZEhJjez58Meta?.alias || [],
    redirect: korjdB9ZW1Vap2OlvYPZUPmWr8IJ_vYYFABKZEhJjez58Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue")
  },
  {
    name: confirmationoo_45EPBcmdoLxepiTC3qhGBpbNBCFmbxqMnXSI8LQ0IcMeta?.name ?? "lang-voucher-voucherpurchaseuuid-confirmation",
    path: confirmationoo_45EPBcmdoLxepiTC3qhGBpbNBCFmbxqMnXSI8LQ0IcMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/confirmation",
    props: confirmationoo_45EPBcmdoLxepiTC3qhGBpbNBCFmbxqMnXSI8LQ0IcMeta?.props ?? false,
    meta: confirmationoo_45EPBcmdoLxepiTC3qhGBpbNBCFmbxqMnXSI8LQ0IcMeta || {},
    alias: confirmationoo_45EPBcmdoLxepiTC3qhGBpbNBCFmbxqMnXSI8LQ0IcMeta?.alias || [],
    redirect: confirmationoo_45EPBcmdoLxepiTC3qhGBpbNBCFmbxqMnXSI8LQ0IcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue")
  },
  {
    name: confirmation2Y2Fe00JI8fBxwKbq_r4mZVYF8N1GmecO2_45veodkwdMMeta?.name ?? "lang-purchase-packagepurchaseuuid-confirmation",
    path: confirmation2Y2Fe00JI8fBxwKbq_r4mZVYF8N1GmecO2_45veodkwdMMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/confirmation",
    props: confirmation2Y2Fe00JI8fBxwKbq_r4mZVYF8N1GmecO2_45veodkwdMMeta?.props ?? false,
    meta: confirmation2Y2Fe00JI8fBxwKbq_r4mZVYF8N1GmecO2_45veodkwdMMeta || {},
    alias: confirmation2Y2Fe00JI8fBxwKbq_r4mZVYF8N1GmecO2_45veodkwdMMeta?.alias || [],
    redirect: confirmation2Y2Fe00JI8fBxwKbq_r4mZVYF8N1GmecO2_45veodkwdMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue")
  },
  {
    name: buyerVIMQkhIO5n78m1o41IstbdhoWGNZsziBg2GI12P7tOgMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-buyer",
    path: buyerVIMQkhIO5n78m1o41IstbdhoWGNZsziBg2GI12P7tOgMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/buyer",
    props: buyerVIMQkhIO5n78m1o41IstbdhoWGNZsziBg2GI12P7tOgMeta?.props ?? false,
    meta: buyerVIMQkhIO5n78m1o41IstbdhoWGNZsziBg2GI12P7tOgMeta || {},
    alias: buyerVIMQkhIO5n78m1o41IstbdhoWGNZsziBg2GI12P7tOgMeta?.alias || [],
    redirect: buyerVIMQkhIO5n78m1o41IstbdhoWGNZsziBg2GI12P7tOgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: buyeryHVnp_45RmmWc2Q8N4jLeeuTTh66VfloRGRBzsDGZL2TUMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-buyer",
    path: buyeryHVnp_45RmmWc2Q8N4jLeeuTTh66VfloRGRBzsDGZL2TUMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/buyer",
    props: buyeryHVnp_45RmmWc2Q8N4jLeeuTTh66VfloRGRBzsDGZL2TUMeta?.props ?? false,
    meta: buyeryHVnp_45RmmWc2Q8N4jLeeuTTh66VfloRGRBzsDGZL2TUMeta || {},
    alias: buyeryHVnp_45RmmWc2Q8N4jLeeuTTh66VfloRGRBzsDGZL2TUMeta?.alias || [],
    redirect: buyeryHVnp_45RmmWc2Q8N4jLeeuTTh66VfloRGRBzsDGZL2TUMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: payment2h8uZtwumJ3B8VKdI7pSo2n2rPbKhpGW0gAYqCeHQ7cMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-payment",
    path: payment2h8uZtwumJ3B8VKdI7pSo2n2rPbKhpGW0gAYqCeHQ7cMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/payment",
    props: payment2h8uZtwumJ3B8VKdI7pSo2n2rPbKhpGW0gAYqCeHQ7cMeta?.props ?? false,
    meta: payment2h8uZtwumJ3B8VKdI7pSo2n2rPbKhpGW0gAYqCeHQ7cMeta || {},
    alias: payment2h8uZtwumJ3B8VKdI7pSo2n2rPbKhpGW0gAYqCeHQ7cMeta?.alias || [],
    redirect: payment2h8uZtwumJ3B8VKdI7pSo2n2rPbKhpGW0gAYqCeHQ7cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue")
  },
  {
    name: challenge9eMHRp6jr_45nIPRwOGGIbZXcVPVY6Ufg_uNtZ60Ud1zUMeta?.name ?? "lang-voucher-voucherpurchaseuuid-redsys-challenge",
    path: challenge9eMHRp6jr_45nIPRwOGGIbZXcVPVY6Ufg_uNtZ60Ud1zUMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/redsys/challenge",
    props: challenge9eMHRp6jr_45nIPRwOGGIbZXcVPVY6Ufg_uNtZ60Ud1zUMeta?.props ?? false,
    meta: challenge9eMHRp6jr_45nIPRwOGGIbZXcVPVY6Ufg_uNtZ60Ud1zUMeta || {},
    alias: challenge9eMHRp6jr_45nIPRwOGGIbZXcVPVY6Ufg_uNtZ60Ud1zUMeta?.alias || [],
    redirect: challenge9eMHRp6jr_45nIPRwOGGIbZXcVPVY6Ufg_uNtZ60Ud1zUMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue")
  },
  {
    name: paymentfmfE_45cM_gBvhKAvDWijeMkBgN7CUeBoAAnR2G8WRengMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-payment",
    path: paymentfmfE_45cM_gBvhKAvDWijeMkBgN7CUeBoAAnR2G8WRengMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/payment",
    props: paymentfmfE_45cM_gBvhKAvDWijeMkBgN7CUeBoAAnR2G8WRengMeta?.props ?? false,
    meta: paymentfmfE_45cM_gBvhKAvDWijeMkBgN7CUeBoAAnR2G8WRengMeta || {},
    alias: paymentfmfE_45cM_gBvhKAvDWijeMkBgN7CUeBoAAnR2G8WRengMeta?.alias || [],
    redirect: paymentfmfE_45cM_gBvhKAvDWijeMkBgN7CUeBoAAnR2G8WRengMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue")
  },
  {
    name: SpaList_46pagea08lvZStwIsfjzLzKQS_45CFHcTSjlp3tQRaAvWf7onSYMeta?.name ?? "Spa by location landing: 0",
    path: SpaList_46pagea08lvZStwIsfjzLzKQS_45CFHcTSjlp3tQRaAvWf7onSYMeta?.path ?? "/pt/spas/",
    props: SpaList_46pagea08lvZStwIsfjzLzKQS_45CFHcTSjlp3tQRaAvWf7onSYMeta?.props ?? false,
    meta: SpaList_46pagea08lvZStwIsfjzLzKQS_45CFHcTSjlp3tQRaAvWf7onSYMeta || {},
    alias: SpaList_46pagea08lvZStwIsfjzLzKQS_45CFHcTSjlp3tQRaAvWf7onSYMeta?.alias || [],
    redirect: SpaList_46pagea08lvZStwIsfjzLzKQS_45CFHcTSjlp3tQRaAvWf7onSYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagea08lvZStwIsfjzLzKQS_45CFHcTSjlp3tQRaAvWf7onSYMeta?.name ?? "Spa by location landing: 1",
    path: SpaList_46pagea08lvZStwIsfjzLzKQS_45CFHcTSjlp3tQRaAvWf7onSYMeta?.path ?? "/pt/spas/sevilla/",
    props: SpaList_46pagea08lvZStwIsfjzLzKQS_45CFHcTSjlp3tQRaAvWf7onSYMeta?.props ?? false,
    meta: SpaList_46pagea08lvZStwIsfjzLzKQS_45CFHcTSjlp3tQRaAvWf7onSYMeta || {},
    alias: SpaList_46pagea08lvZStwIsfjzLzKQS_45CFHcTSjlp3tQRaAvWf7onSYMeta?.alias || [],
    redirect: SpaList_46pagea08lvZStwIsfjzLzKQS_45CFHcTSjlp3tQRaAvWf7onSYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagea08lvZStwIsfjzLzKQS_45CFHcTSjlp3tQRaAvWf7onSYMeta?.name ?? "Spa by location landing: 2",
    path: SpaList_46pagea08lvZStwIsfjzLzKQS_45CFHcTSjlp3tQRaAvWf7onSYMeta?.path ?? "/pt/spas/sevilla/sevilla/",
    props: SpaList_46pagea08lvZStwIsfjzLzKQS_45CFHcTSjlp3tQRaAvWf7onSYMeta?.props ?? false,
    meta: SpaList_46pagea08lvZStwIsfjzLzKQS_45CFHcTSjlp3tQRaAvWf7onSYMeta || {},
    alias: SpaList_46pagea08lvZStwIsfjzLzKQS_45CFHcTSjlp3tQRaAvWf7onSYMeta?.alias || [],
    redirect: SpaList_46pagea08lvZStwIsfjzLzKQS_45CFHcTSjlp3tQRaAvWf7onSYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pagea08lvZStwIsfjzLzKQS_45CFHcTSjlp3tQRaAvWf7onSYMeta?.name ?? "Spa by location landing: 3",
    path: SpaList_46pagea08lvZStwIsfjzLzKQS_45CFHcTSjlp3tQRaAvWf7onSYMeta?.path ?? "/pt/spas/sevilla/umbrete/",
    props: SpaList_46pagea08lvZStwIsfjzLzKQS_45CFHcTSjlp3tQRaAvWf7onSYMeta?.props ?? false,
    meta: SpaList_46pagea08lvZStwIsfjzLzKQS_45CFHcTSjlp3tQRaAvWf7onSYMeta || {},
    alias: SpaList_46pagea08lvZStwIsfjzLzKQS_45CFHcTSjlp3tQRaAvWf7onSYMeta?.alias || [],
    redirect: SpaList_46pagea08lvZStwIsfjzLzKQS_45CFHcTSjlp3tQRaAvWf7onSYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaDetail_46page9AlauHM_45F_OabFbldmO6NFNg3T6D6z1ttL7coycWM_45QMeta?.name ?? "Spa landing: 0",
    path: SpaDetail_46page9AlauHM_45F_OabFbldmO6NFNg3T6D6z1ttL7coycWM_45QMeta?.path ?? "/pt/spas/sevilla/spa-kumaras/",
    props: SpaDetail_46page9AlauHM_45F_OabFbldmO6NFNg3T6D6z1ttL7coycWM_45QMeta?.props ?? false,
    meta: SpaDetail_46page9AlauHM_45F_OabFbldmO6NFNg3T6D6z1ttL7coycWM_45QMeta || {},
    alias: SpaDetail_46page9AlauHM_45F_OabFbldmO6NFNg3T6D6z1ttL7coycWM_45QMeta?.alias || [],
    redirect: SpaDetail_46page9AlauHM_45F_OabFbldmO6NFNg3T6D6z1ttL7coycWM_45QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page9AlauHM_45F_OabFbldmO6NFNg3T6D6z1ttL7coycWM_45QMeta?.name ?? "Spa landing: 1",
    path: SpaDetail_46page9AlauHM_45F_OabFbldmO6NFNg3T6D6z1ttL7coycWM_45QMeta?.path ?? "/pt/spas/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/",
    props: SpaDetail_46page9AlauHM_45F_OabFbldmO6NFNg3T6D6z1ttL7coycWM_45QMeta?.props ?? false,
    meta: SpaDetail_46page9AlauHM_45F_OabFbldmO6NFNg3T6D6z1ttL7coycWM_45QMeta || {},
    alias: SpaDetail_46page9AlauHM_45F_OabFbldmO6NFNg3T6D6z1ttL7coycWM_45QMeta?.alias || [],
    redirect: SpaDetail_46page9AlauHM_45F_OabFbldmO6NFNg3T6D6z1ttL7coycWM_45QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page9AlauHM_45F_OabFbldmO6NFNg3T6D6z1ttL7coycWM_45QMeta?.name ?? "Spa landing: 2",
    path: SpaDetail_46page9AlauHM_45F_OabFbldmO6NFNg3T6D6z1ttL7coycWM_45QMeta?.path ?? "/pt/spas/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/",
    props: SpaDetail_46page9AlauHM_45F_OabFbldmO6NFNg3T6D6z1ttL7coycWM_45QMeta?.props ?? false,
    meta: SpaDetail_46page9AlauHM_45F_OabFbldmO6NFNg3T6D6z1ttL7coycWM_45QMeta || {},
    alias: SpaDetail_46page9AlauHM_45F_OabFbldmO6NFNg3T6D6z1ttL7coycWM_45QMeta?.alias || [],
    redirect: SpaDetail_46page9AlauHM_45F_OabFbldmO6NFNg3T6D6z1ttL7coycWM_45QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46page9AlauHM_45F_OabFbldmO6NFNg3T6D6z1ttL7coycWM_45QMeta?.name ?? "Spa landing: 3",
    path: SpaDetail_46page9AlauHM_45F_OabFbldmO6NFNg3T6D6z1ttL7coycWM_45QMeta?.path ?? "/pt/spas/sevilla/termas-de-hispalis/",
    props: SpaDetail_46page9AlauHM_45F_OabFbldmO6NFNg3T6D6z1ttL7coycWM_45QMeta?.props ?? false,
    meta: SpaDetail_46page9AlauHM_45F_OabFbldmO6NFNg3T6D6z1ttL7coycWM_45QMeta || {},
    alias: SpaDetail_46page9AlauHM_45F_OabFbldmO6NFNg3T6D6z1ttL7coycWM_45QMeta?.alias || [],
    redirect: SpaDetail_46page9AlauHM_45F_OabFbldmO6NFNg3T6D6z1ttL7coycWM_45QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 0",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/spa-kumaras/etna-circuito-massagem-de-aromaterapia/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 1",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/spa-kumaras/massagem-com-velas-quentes/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 2",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/spa-kumaras/circuito-das-termas-de-kumaras/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 3",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/spa-kumaras/terapia-de-chocolate-termoactivo/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 4",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/spa-kumaras/nirvana-circuito-privado-massagem-com-velas/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 5",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/spa-kumaras/kumaras-sensations-privado/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 6",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/spa-kumaras/paani-plus-circuito-massagem-de-relaxamento/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 7",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/spa-kumaras/massagem-com-pedras-vulcanicas/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 8",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/spa-kumaras/massagem-de-relaxamento/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 9",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/spa-kumaras/vinoterapia/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 10",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/spa-kumaras/champi-massagem/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 11",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/esfoliante-aromatico-energizante-com-especiarias-raras-e-preciosas/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 12",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/beldi-sabonete-preto-esfoliante-purificante/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 13",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/ko-bi-do-global-anti-aging-precious-treatment/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 14",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/massagem-de-aromaterapia/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 15",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/passe-de-um-dia-de-sexta-feira-a-domingo/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 16",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/passe-diario-de-segunda-a-quinta-feira/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 17",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/relaxar-e-desfrutar/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 18",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/massagem-oriental-de-relaxamento-50/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 19",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/massagem-balinesa-wrap-around/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 20",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/massagem-desportiva/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 21",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/massagem-indiana-ayurvedica-revigorante/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 22",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/tratamento-regenerador-ritual-de-flores-e-frutos-de-bali/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 23",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/massagem-oriental-relaxante-30/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 24",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/massagem-as-costas/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 25",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/tratamento-de-alisamento-e-firmeza-ko-bi-do/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 26",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/massagem-sensorial-a-cabeca/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 27",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/cool-evasion-spa-massagem/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 28",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/cool-wellness-brunch-para-2-pessoas-spa-ritual-brunch/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 29",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/beleza-essencial-para-uma-pessoa/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 30",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/tired-legs-para-uma-pessoa/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 31",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/circuito-de-spa-privado-para-duas-pessoas/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 32",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/cool-relax-spa-massagem-gastronomia/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 33",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/massagem-de-gravidez-para-uma-pessoa/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 34",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/circuito-de-spa-para-uma-pessoa/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 35",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/alegria-de-azahar-para-uma-pessoa/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 36",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/massagem-de-tecidos-profundos-para-uma-pessoa/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 37",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/cidade-de-purificacao-para-uma-pessoa/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 38",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/aromaterapia-relax-para-uma-pessoa/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 39",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/cool-wellness-para-2-pessoas-spa-ritual/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 40",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/coolspa-by-coolrooms-palacio-de-villapanes/eternal-youth-para-uma-pessoa/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 41",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/termas-de-hispalis/minerva-circuito-massagem-holistica/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 42",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/termas-de-hispalis/marenostrum-circuito-massagem/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 43",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/termas-de-hispalis/triton-circuito-flotarium/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 44",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/termas-de-hispalis/ritual-de-relaxamento/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 45",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/termas-de-hispalis/flotario-de-30/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 46",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/termas-de-hispalis/flotarium-15/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 47",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/termas-de-hispalis/circuito-hispalis/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 48",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/termas-de-hispalis/ritual-al-andalus/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 49",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/termas-de-hispalis/eros-circuito-privado-ritual-al-andalus/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 50",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/termas-de-hispalis/terapia-do-vinho/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 51",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/termas-de-hispalis/terapia-termoactiva-com-chocolate/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 52",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/termas-de-hispalis/neptuno-circuito-flotarium-massagem/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 53",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/termas-de-hispalis/hispalis-sensations-circuito-privado/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 54",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/termas-de-hispalis/massagem-com-pedras-vulcanicas/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.name ?? "Service landing: 55",
    path: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.path ?? "/pt/spas/sevilla/termas-de-hispalis/massagem-de-relaxamento/",
    props: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.props ?? false,
    meta: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta || {},
    alias: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.alias || [],
    redirect: ServiceDetail_46pagezapLupZFYqB_45Anf2X16LecFLO7DoRyNgqi4TDOMh1lAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-bf3f878a-0b05-4fa6-9d69-e92cd53714f1/b2c-build/core/pages/service/ServiceDetail.page.vue")
  }
]